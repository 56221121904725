import React, { useEffect } from "react";
import { useState } from "react";
import axios from 'axios';

const ContentfulImage = (assetId) => {
    const [imageUrl, setImageUrl] = useState([]);

    useEffect(() => {
        const fetchImage = async (assetId) => {
            const url = `https://cdn.contentful.com/spaces/muawvkdwcb84/environments/master/assets/${assetId}?access_token=tv6wB_pdLWmMO_XJ9EDcaDkqC5cewpa21jqsT5MLsUA`;
            const response = await axios.get(url);
            setImageUrl(response.data["fields"]["file"].url);
        }
        fetchImage(assetId["assetId"]);
    }, []);

    return (
        <React.Fragment>
              <img src={imageUrl} alt="" className="product-thumb" />
        </React.Fragment>
    )
}

export default ContentfulImage
