import React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import SingleProduct from "../../components/SingleProduct/SingleProduct";
import EbayItemsWidget from '../../common/ebaySearchBar/';
import CardMedia from '@material-ui/core/CardMedia';
import logo from '../../assets/img/ebay.png';

import {useParams} from "react-router-dom";

function Product() {
    const { keyword } = useParams();
    return (
        <React.Fragment>
            <Header />
            
            {/* <div>
                <p>
                    <strong>Match Props: </strong>
                    <code>{JSON.stringify(match, null, 2)}</code>
                </p>
                <p>
                    <strong>Location Props: </strong>
                    <code>{JSON.stringify(location, null, 2)}</code>
                </p>
            </div> */}

            <section style={{ padding: 20 }}>
                    <div
                        style={{
                            margin: '0 auto',
                            width: '70%',
                        }}>

                        <EbayItemsWidget
                            carouselOptions={{
                                animation: 'slide',
                                autoPlay: true,
                                interval: 5000,
                                indicators: false,
                                navButtonsAlwaysInvisible: false,
                                navButtonsAlwaysVisible: false,
                                stopAutoPlayOnHover: true
                            }}
                            defaultView="gallery"
                            hideSearchBar={true}
                            hideSortOptions={true}
                            hideSettings={false}
                            hideTotal={true}
                            hideViewOptions={true}
                            limit={50}
                            imageSearchEndpoint="https://ebay-node.vercel.app/api/search_by_image"
                            searchEndpoint="https://ebay-node.vercel.app/api/search"
                            searchKeyword={keyword}
                            isSingleDeal={false}
                        />
                    </div>
                </section>



            <Footer />
        </React.Fragment>      
    );
}

export default Product;
