import styled, {css} from "styled-components";

const CategoryDiv = styled.div`
  text-align: left;
  padding: 1.5rem;
  margin-top: 0.5rem;
  font-size: 3rem;
  display: flex;
  flex-direction: column;
`;

const BrandDiv = styled.div`
  font-size: 2rem;
  width: 90%;
  margin: auto;
`;

const KeywordDiv = styled.div`
  font-size: 1.5rem;
  width: 90%;
  margin: auto;
`;

export {
  CategoryDiv, 
  BrandDiv, 
  KeywordDiv
};
