import React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import categoryData from "../Sitemap/categoryData.json";
import 
{ 
  CategoryDiv,
  BrandDiv, 
  KeywordDiv
} from "../Tags/styledComponents";

function Categories() {

    return (
        <React.Fragment>
            <Header />
              <div className="product-widget-area">
                      <div className="zigzag-bottom"></div>
                      <div className="container">

                              {
                                categoryData &&
                                categoryData.length > 0 &&
                                categoryData.map((item, index) => (
                                  <CategoryDiv className="row" key={"category_"+index}>
                                      <a href={item.url} key={"category_link_"+index}>{item.categoryName}</a> 
                                      {
                                        item.subCategory.map((subBrands) => (
                                            <React.Fragment>
                                              <BrandDiv className="text-indent-right" key={"brands_"+index}>
                                                <a href={subBrands.url} key={"brand_link_"+index}>{subBrands.categoryName}</a>
                                                {
                                                  subBrands?.subCategory?.map((keyword) => (
                                                    <React.Fragment>
                                                        <KeywordDiv key={"keyword_"+index}>
                                                            <a href={keyword.url} key={"keyword_link_"+index}>{keyword.categoryName}</a>
                                                        </KeywordDiv>
                                                    </React.Fragment>
                                                  ))
                                                }
                                              </BrandDiv>
                                            </React.Fragment>
                                        ))
                                      }
                                  </CategoryDiv>
                                ))
                              }
                         
                        </div>
              </div>
            <Footer />
        </React.Fragment>
      
    );
}

export default Categories;

