import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types'
import Button from '../Button'
import { DivSearchBar } from '../Header/styledComponents';
import Logo from '../../assets/img/logo.png';
import PriceLavaLogo from '../../assets/img/pricelavalogo.jpg';
import {useNavigate } from 'react-router-dom';

const Header = ({title}) => {

    const [searchQuery, setSearchQuery] = useState('');
    
    //console.log("Environment Variable:" + process.env.REACT_APP_SPACE_ID);

    return (
        <React.Fragment>
            {/* <header className='header'>
                <h1>{title}</h1>
                <Button color='green' text='Add' onClick={onClick}></Button>
                this is the header component
            </header>
            <DivHeader>
                <DivSubHeader>
                This the header component
                </DivSubHeader>
  
            </DivHeader> */}

           <div className="header-area">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8" >
                            <div className="user-menu">
                                <ul>
                                    <li></li>
                                    {/* <li><a href="#"><i className="fa fa-user"></i> My Account</a></li>
                                    <li><a href="#"><i className="fa fa-heart"></i> Wishlist</a></li>
                                    <li><a href="cart.html"><i className="fa fa-user"></i> My Cart</a></li>
                                    <li><a href="checkout.html"><i className="fa fa-user"></i> Checkout</a></li>
                                    <li><a href="#"><i className="fa fa-user"></i> Login</a></li>  */}
                                </ul>
                            </div>
                        </div>
                        
                        {/* <div className="col-md-4">
                            <div className="header-right">
                                <ul className="list-unstyled list-inline">
                                    <li className="dropdown dropdown-small">
                                        <a data-toggle="dropdown" data-hover="dropdown" className="dropdown-toggle" href="#"><span className="key">currency :</span><span className="value">USD </span><b className="caret"></b></a>
                                        <ul className="dropdown-menu">
                                            <li><a href="#">USD</a></li>
                                            <li><a href="#">INR</a></li>
                                            <li><a href="#">GBP</a></li>
                                        </ul>
                                    </li>

                                    <li className="dropdown dropdown-small">
                                        <a data-toggle="dropdown" data-hover="dropdown" className="dropdown-toggle" href="#"><span className="key">language :</span><span className="value">English </span><b className="caret"></b></a>
                                        <ul className="dropdown-menu">
                                            <li><a href="#">English</a></li>
                                            <li><a href="#">French</a></li>
                                            <li><a href="#">German</a></li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div> */}


                    </div>
                </div>
            </div> 


            {/* <!-- site branding area --> */}
            <div className="site-branding-area">
                <div className="container">
                    <div className="row">
                        <div className="col-0 col-md-0 col-lg-3">
                            <div className="logo">
                                <h1>
                                    <a href="https://pricelava.com"><img width="100" src={PriceLavaLogo} /></a>
                                
                                </h1>
                            </div>
                        </div>
                        
                        {/* <div className="col-sm-6">
                            <div className="shopping-item">
                                <a href="cart.html">Cart - <span className="cart-amunt">$100</span> <i className="fa fa-shopping-cart"></i> <span className="product-count">5</span></a>
                            </div>
                        </div> */}

                        <DivSearchBar className="col-12 col-md-12 col-lg-9 d-flex align-items-center justify-content-end mb-5 mb-lg-0 mb-xl-0">
                                <div className="d-flex m-auto">
                                    <form className="d-flex" action={"/product/" + searchQuery}>
                                        <input type="textbox" onChange={event => setSearchQuery(event.target.value)} value={searchQuery} placeholder="Search The Lowest Prices For Products" />
                                        <input type="submit" value="Search" />
                                    </form>
                                </div>
                        </DivSearchBar> 


                    </div>
                </div>
            </div> 
            {/* <!-- End site branding area --> */}
    

            {/* <!-- Main Menu --> */}
            <div className="mainmenu-area">
                <div className="container">
                    <div className="row">
                        <div className="navbar-header">
                            {/* <button type="button" className="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
                                <span className="sr-only">Toggle navigation</span>
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                            </button> */}
                        </div> 
                        <div className="navbar-collapse">
                            <ul className="nav navbar-nav">
                                <li className="active"><a href="/">Home</a></li>
                                {/* <li><a href="shop.html">Shop page</a></li>
                                <li><a href="single-product.html">Single product</a></li>
                                <li><a href="cart.html">Cart</a></li>
                                <li><a href="checkout.html">Checkout</a></li>
                                <li><a href="#">Category</a></li>
                                <li><a href="#">Others</a></li>
                                <li><a href="#">Contact</a></li> */}
                            </ul>

     


                        </div>  
                    </div>
                </div>
            </div> 
             {/* <!-- End Main Menu --> */}


        </React.Fragment>
      
    )
}

Header.defaultProps = {
    title: 'Task Tracker',
}

Header.propTypes = {
    title: PropTypes.string.isRequired,
}

//CSS in JS
// const headingStyle = {
//     color: 'red', 
//     backgroundColor:'black'
// }

export default Header
