import React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";


function Sitemap() {

    return (
        <React.Fragment>
            <Header />
            
            <Footer />
        </React.Fragment>
      
    );
}

export default Sitemap;

