import React from 'react';
import ReactDOM from 'react-dom/client';

import './index.css';

//Bootstrap 
import './assets/css/bootstrap.min.css';
//Fonts
import './assets/css/font-awesome.min.css';
//CSS 
import './assets/css/owl.carousel.css';
import './assets/css/style.css';
import './assets/css/responsive.css';

import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from "./pages/Home/Home";
import About from "./pages/About/About";
import Product from "./pages/Product/index";
import Deal from "./pages/Deal/index";
import Sitemap from "./pages/Sitemap/index";
import Tags from "./pages/Tags/index";


const root = ReactDOM.createRoot(document.getElementById('root'));
const helmetContext = {};
root.render(
      <React.StrictMode> 
         <BrowserRouter>
         <Routes>
               <Route exact path="/" element={<Home />} />
               <Route exact path="/about" element={<About />} />
               <Route exact path="/sitemap" element={<Sitemap />} />
               <Route exact path="/tags" element={<Tags />} />
               <Route exact path="/product/:keyword" element={<Product />} />
               <Route exact path="/deal/:keyword" element={<Deal />} />
         </Routes>
         </BrowserRouter>
      </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
