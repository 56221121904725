import React, { useEffect } from "react";
import { useState } from "react";
import axios from 'axios';
//import { DivHeader, DivSubHeader } from '../Header/styledComponents';

import product1 from "../../assets/img/product-1.jpg";
import product2 from "../../assets/img/product-2.jpg";
import product3 from "../../assets/img/product-3.jpg";
import product4 from "../../assets/img/product-4.jpg";
import product5 from "../../assets/img/product-5.jpg";
import product6 from "../../assets/img/product-6.jpg";

import productThumb1 from "../../assets/img/product-thumb-1.jpg";
import productThumb2 from "../../assets/img/product-thumb-2.jpg";
import productThumb3 from "../../assets/img/product-thumb-3.jpg";
import productThumb4 from "../../assets/img/product-thumb-4.jpg";

import EbayItemsWidget from '../../common/ebaySearchBar/';
import CardMedia from '@material-ui/core/CardMedia';
import logo from '../../assets/img/ebay.png';
import { useParams } from "react-router-dom";

import ImageContent from '../Image/Image';

const MainProducts = () => {

    const [dealsData, setDealsData] = useState([]);
    // const [actualData, setActualData] = useState();
    // const contentful = require("contentful");
    // const client = contentful.createClient({
    // // This is the space ID. A space is like a project folder in Contentful terms
    // space: "muawvkdwcb84",
    // // This is the access token for this space. Normally you get both ID and the token in the Contentful web app
    // accessToken: "tv6wB_pdLWmMO_XJ9EDcaDkqC5cewpa21jqsT5MLsUA"
    // });
    // // This API call will request an entry with the specified ID from the space defined at the top, using a space-specific access token.

//get images 
//https://cdn.contentful.com/spaces/muawvkdwcb84/environments/master/assets/5l2r4MltQ9LwgxYut9GtYT?access_token=tv6wB_pdLWmMO_XJ9EDcaDkqC5cewpa21jqsT5MLsUA
//https://cdn.contentful.com/spaces/muawvkdwcb84/environments/master/assets/[assetid]]?access_token=tv6wB_pdLWmMO_XJ9EDcaDkqC5cewpa21jqsT5MLsUA

    useEffect(() => {
        const fetchAllDeals = async () => {
             const response = await axios.get('https://cdn.contentful.com/spaces/muawvkdwcb84/environments/master/entries?access_token=tv6wB_pdLWmMO_XJ9EDcaDkqC5cewpa21jqsT5MLsUA')
             setDealsData(response.data.items);
             //console.log(JSON.stringify(response.data.items));
        }
        fetchAllDeals(); 
    }, []);


    // const fetchImage = async (assetId) => {
    //     const url = `https://cdn.contentful.com/spaces/muawvkdwcb84/environments/master/assets/${assetId}?access_token=tv6wB_pdLWmMO_XJ9EDcaDkqC5cewpa21jqsT5MLsUA`;
    //     const response = await axios.get(url);
    //     //console.log("image data: " + JSON.stringify(response.data["fields"]["file"].url));
    //     //response.data["fields"]["file"].url
    //     //alert({assetId});
    //     console.log({assetId})
    //     return response;
    //     //const response = await axios.get('https://cdn.contentful.com/spaces/muawvkdwcb84/environments/master/assets/5l2r4MltQ9LwgxYut9GtYT?access_token=tv6wB_pdLWmMO_XJ9EDcaDkqC5cewpa21jqsT5MLsUA');
    //     //console.log("this is the image assetIdd!!:" + url);
    // }

    //fetchImage('2zWclUKa5ZXDCKdxTVBAeO');

    // if(typeof dealsData === 'undefined') {
    //     console.log("it is undefined");
    // } else {
    //     console.log(Object.values(dealsData));
    // }

    return (
        <React.Fragment>

             <div className="product-widget-area">
                    <div className="zigzag-bottom"></div>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="single-product-widget d-flex flex-row">
                                    
                                    {dealsData.map((item, index) => {
                                        return (
                                            <div className="single-wid-product col-md-4" key={index}>
                                                <a href={"/deal/" + item["fields"].keyword.toString() + "?id=" + item["sys"].id.toString()}>
                                                    <ImageContent assetId={item["fields"]["image"]["sys"].id.toString()}></ImageContent>
                                                </a>
                                                <h2><a href={"/deal/" + item["fields"].keyword.toString() + "?id=" + item["sys"].id.toString()}>{item["fields"].name}</a></h2>
                                                <div className="product-wid-rating">
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                </div>
                                                <div className="product-wid-price">
                                                    <ins>${item["fields"].price}</ins> <del>${item["fields"].retailPrice}</del>
                                                </div>                            
                                            </div>
                                        )
                                    })}

                                </div>
                            </div>

                        

                            {/* <div className="col-md-4">
                                <div className="single-product-widget">
                                    
                                    
                                    <div className="single-wid-product">
                                        <a href="single-product.html"><img src={productThumb4} alt="" className="product-thumb" /></a>
                                        <h2><a href="single-product.html">Sony playstation microsoft</a></h2>
                                        <div className="product-wid-rating">
                                            <i className="fa fa-star"></i>
                                            <i className="fa fa-star"></i>
                                            <i className="fa fa-star"></i>
                                            <i className="fa fa-star"></i>
                                            <i className="fa fa-star"></i>
                                        </div>
                                        <div className="product-wid-price">
                                            <ins>$400.00</ins> <del>$425.00</del>
                                        </div>                            
                                    </div>

                                    <div className="single-wid-product">
                                        <a href="single-product.html"><img src={productThumb1} alt="" className="product-thumb" /></a>
                                        <h2><a href="single-product.html">Sony Smart Air Condtion</a></h2>
                                        <div className="product-wid-rating">
                                            <i className="fa fa-star"></i>
                                            <i className="fa fa-star"></i>
                                            <i className="fa fa-star"></i>
                                            <i className="fa fa-star"></i>
                                            <i className="fa fa-star"></i>
                                        </div>
                                        <div className="product-wid-price">
                                            <ins>$400.00</ins> <del>$425.00</del>
                                        </div>                            
                                    </div>

                                    <div className="single-wid-product">
                                        <a href="single-product.html"><img src={productThumb2} alt="" className="product-thumb" /></a>
                                        <h2><a href="single-product.html">Samsung gallaxy note 4</a></h2>
                                        <div className="product-wid-rating">
                                            <i className="fa fa-star"></i>
                                            <i className="fa fa-star"></i>
                                            <i className="fa fa-star"></i>
                                            <i className="fa fa-star"></i>
                                            <i className="fa fa-star"></i>
                                        </div>
                                        <div className="product-wid-price">
                                            <ins>$400.00</ins> <del>$425.00</del>
                                        </div>                            
                                    </div>

                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="single-product-widget">
                                  
                                  
                                    <div className="single-wid-product">
                                        <a href="single-product.html"><img src={productThumb3} alt="" className="product-thumb" /></a>
                                        <h2><a href="single-product.html">Apple new i phone 6</a></h2>
                                        <div className="product-wid-rating">
                                            <i className="fa fa-star"></i>
                                            <i className="fa fa-star"></i>
                                            <i className="fa fa-star"></i>
                                            <i className="fa fa-star"></i>
                                            <i className="fa fa-star"></i>
                                        </div>
                                        <div className="product-wid-price">
                                            <ins>$400.00</ins> <del>$425.00</del>
                                        </div>                            
                                    </div>

                                    <div className="single-wid-product">
                                        <a href="single-product.html"><img src={productThumb4} alt="" className="product-thumb" /></a>
                                        <h2><a href="single-product.html">Samsung gallaxy note 4</a></h2>
                                        <div className="product-wid-rating">
                                            <i className="fa fa-star"></i>
                                            <i className="fa fa-star"></i>
                                            <i className="fa fa-star"></i>
                                            <i className="fa fa-star"></i>
                                            <i className="fa fa-star"></i>
                                        </div>
                                        <div className="product-wid-price">
                                            <ins>$400.00</ins> <del>$425.00</del>
                                        </div>                            
                                    </div>

                                    <div className="single-wid-product">
                                        <a href="single-product.html"><img src={productThumb1} alt="" className="product-thumb" /></a>
                                        <h2><a href="single-product.html">Sony playstation microsoft</a></h2>
                                        <div className="product-wid-rating">
                                            <i className="fa fa-star"></i>
                                            <i className="fa fa-star"></i>
                                            <i className="fa fa-star"></i>
                                            <i className="fa fa-star"></i>
                                            <i className="fa fa-star"></i>
                                        </div>
                                        <div className="product-wid-price">
                                            <ins>$400.00</ins> <del>$425.00</del>
                                        </div>                            
                                    </div>

                                </div>
                            </div> */}
                        </div>
                    </div>
                </div> 




{/* 

                <div className="product-widget-area">
                    <div className="zigzag-bottom"></div>
                    <div className="container">
                        <div className="row d-flex">
                            <div className="col-md-4">
                                <div className="single-product-widget">
                                    <h2 className="product-wid-title">Top Sellers</h2>
                                    <a href="" className="wid-view-more">View All</a>
                                    <div className="single-wid-product">
                                        <a href="single-product.html"><img src={productThumb1} alt="" className="product-thumb" /></a>
                                        <h2><a href="single-product.html">Sony Smart TV - 2015</a></h2>
                                        <div className="product-wid-rating">
                                            <i className="fa fa-star"></i>
                                            <i className="fa fa-star"></i>
                                            <i className="fa fa-star"></i>
                                            <i className="fa fa-star"></i>
                                            <i className="fa fa-star"></i>
                                        </div>
                                        <div className="product-wid-price">
                                            <ins>$400.00</ins> <del>$425.00</del>
                                        </div>                            
                                    </div>
                                    <div className="single-wid-product">
                                        <a href="single-product.html"><img src={productThumb2} alt="" className="product-thumb" /></a>
                                        <h2><a href="single-product.html">Apple new mac book 2015</a></h2>
                                        <div className="product-wid-rating">
                                            <i className="fa fa-star"></i>
                                            <i className="fa fa-star"></i>
                                            <i className="fa fa-star"></i>
                                            <i className="fa fa-star"></i>
                                            <i className="fa fa-star"></i>
                                        </div>
                                        <div className="product-wid-price">
                                            <ins>$400.00</ins> <del>$425.00</del>
                                        </div>                            
                                    </div>
                                    <div className="single-wid-product">
                                        <a href="single-product.html"><img src={productThumb3} alt="" className="product-thumb" /></a>
                                        <h2><a href="single-product.html">Apple new i phone 6</a></h2>
                                        <div className="product-wid-rating">
                                            <i className="fa fa-star"></i>
                                            <i className="fa fa-star"></i>
                                            <i className="fa fa-star"></i>
                                            <i className="fa fa-star"></i>
                                            <i className="fa fa-star"></i>
                                        </div>
                                        <div className="product-wid-price">
                                            <ins>$400.00</ins> <del>$425.00</del>
                                        </div>                            
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="single-product-widget">
                                    <h2 className="product-wid-title">Recently Viewed</h2>
                                    <a href="#" className="wid-view-more">View All</a>
                                    <div className="single-wid-product">
                                        <a href="single-product.html"><img src={productThumb4} alt="" className="product-thumb" /></a>
                                        <h2><a href="single-product.html">Sony playstation microsoft</a></h2>
                                        <div className="product-wid-rating">
                                            <i className="fa fa-star"></i>
                                            <i className="fa fa-star"></i>
                                            <i className="fa fa-star"></i>
                                            <i className="fa fa-star"></i>
                                            <i className="fa fa-star"></i>
                                        </div>
                                        <div className="product-wid-price">
                                            <ins>$400.00</ins> <del>$425.00</del>
                                        </div>                            
                                    </div>
                                    <div className="single-wid-product">
                                        <a href="single-product.html"><img src={productThumb1} alt="" className="product-thumb" /></a>
                                        <h2><a href="single-product.html">Sony Smart Air Condtion</a></h2>
                                        <div className="product-wid-rating">
                                            <i className="fa fa-star"></i>
                                            <i className="fa fa-star"></i>
                                            <i className="fa fa-star"></i>
                                            <i className="fa fa-star"></i>
                                            <i className="fa fa-star"></i>
                                        </div>
                                        <div className="product-wid-price">
                                            <ins>$400.00</ins> <del>$425.00</del>
                                        </div>                            
                                    </div>
                                    <div className="single-wid-product">
                                        <a href="single-product.html"><img src={productThumb2} alt="" className="product-thumb" /></a>
                                        <h2><a href="single-product.html">Samsung gallaxy note 4</a></h2>
                                        <div className="product-wid-rating">
                                            <i className="fa fa-star"></i>
                                            <i className="fa fa-star"></i>
                                            <i className="fa fa-star"></i>
                                            <i className="fa fa-star"></i>
                                            <i className="fa fa-star"></i>
                                        </div>
                                        <div className="product-wid-price">
                                            <ins>$400.00</ins> <del>$425.00</del>
                                        </div>                            
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="single-product-widget">
                                    <h2 className="product-wid-title">Top New</h2>
                                    <a href="#" className="wid-view-more">View All</a>
                                    <div className="single-wid-product">
                                        <a href="single-product.html"><img src={productThumb3} alt="" className="product-thumb" /></a>
                                        <h2><a href="single-product.html">Apple new i phone 6</a></h2>
                                        <div className="product-wid-rating">
                                            <i className="fa fa-star"></i>
                                            <i className="fa fa-star"></i>
                                            <i className="fa fa-star"></i>
                                            <i className="fa fa-star"></i>
                                            <i className="fa fa-star"></i>
                                        </div>
                                        <div className="product-wid-price">
                                            <ins>$400.00</ins> <del>$425.00</del>
                                        </div>                            
                                    </div>
                                    <div className="single-wid-product">
                                        <a href="single-product.html"><img src={productThumb4} alt="" className="product-thumb" /></a>
                                        <h2><a href="single-product.html">Samsung gallaxy note 4</a></h2>
                                        <div className="product-wid-rating">
                                            <i className="fa fa-star"></i>
                                            <i className="fa fa-star"></i>
                                            <i className="fa fa-star"></i>
                                            <i className="fa fa-star"></i>
                                            <i className="fa fa-star"></i>
                                        </div>
                                        <div className="product-wid-price">
                                            <ins>$400.00</ins> <del>$425.00</del>
                                        </div>                            
                                    </div>
                                    <div className="single-wid-product">
                                        <a href="single-product.html"><img src={productThumb1} alt="" className="product-thumb" /></a>
                                        <h2><a href="single-product.html">Sony playstation microsoft</a></h2>
                                        <div className="product-wid-rating">
                                            <i className="fa fa-star"></i>
                                            <i className="fa fa-star"></i>
                                            <i className="fa fa-star"></i>
                                            <i className="fa fa-star"></i>
                                            <i className="fa fa-star"></i>
                                        </div>
                                        <div className="product-wid-price">
                                            <ins>$400.00</ins> <del>$425.00</del>
                                        </div>                            
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>  */}
                {/* <!-- End product widget area --> */}

                {/* <div className="promo-area">
                    <div className="zigzag-bottom"></div>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-3 col-sm-6">
                                <div className="single-promo promo1">
                                    <i className="fa fa-refresh"></i>
                                    <p>30 Days return</p>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-6">
                                <div className="single-promo promo2">
                                    <i className="fa fa-truck"></i>
                                    <p>Free shipping</p>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-6">
                                <div className="single-promo promo3">
                                    <i className="fa fa-lock"></i>
                                    <p>Secure payments</p>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-6">
                                <div className="single-promo promo4">
                                    <i className="fa fa-gift"></i>
                                    <p>New products</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* <!-- End promo area --> */}

        </React.Fragment>
      
    )
}


export default MainProducts
