import React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import MainProducts from "../../components/MainProducts/MainProducts";

function About() {

    return (
        <React.Fragment>
            <Header />
            
                This is the about us page. 


            <Footer />
        </React.Fragment>
      
    );
}

export default About;
