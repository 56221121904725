import styled from 'styled-components';

export const DivHeader = styled.div`
    position: relative;
    top: 0px;
    right: 0px;
    left: 0px;
    transition: all 250ms ease-in-out 0s;
    z-index: 99;
`;

export const DivSubHeader = styled.div`
    position: relative;
    z-index: 1;
    height: 80px;
    background: rgb(255, 255, 255);
`;

export const DivSearchBar = styled.div`
    input[type='textbox'] {
        min-width: 30rem;
        min-height: 2.85rem;
        @media only screen and (min-width: 100px) and (max-width: 800px) { 
            min-width: 10rem;
            width:100%;
        }
    }
    input[type='submit'] {
        @media only screen and (min-width: 100px) and (max-width: 800px) { 
            min-width: 8rem;
        }
    }
`;