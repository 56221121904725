import React, { useEffect } from 'react';
import { useState } from "react";
import axios from 'axios';
import { ffParam } from '../../common/Constants';
import EbayItemsWidget from '../../common/ebaySearchBar';
import logo from '../../assets/img/ebay.png';
import {useParams, useSearchParams} from "react-router-dom";
import ImageContent from '../Image/Image';

import {
    TwitterShareButton,
    TwitterIcon
  } from "react-share";


const utils = require('../../common/Utils');


const SingleDeal = (props) => {
    //Single deals are for manually added products. 
    
    //console.log('ebay items:' + JSON.stringify(props.items));
    const { keyword } = useParams();
    //console.log('keyword: ' + keyword);
    const [searchUrlParams, setSearchUrlParams] = useSearchParams();
    const [singleDealData, setSingleDealData] = useState([]);
    const [assetId, setAssetId] = useState();
    //console.log("this is my id: !!" + searchUrlParams.get("id"));

    // useEffect(() => {
    //     utils.triggerImpression(props.items);
    // }, [props.items]);

    useEffect(() => {
        const fetchSingleDeal = async (id) => {
             let spaceId = process.env.REACT_APP_SPACE_ID;
             let accessToken = process.env.REACT_APP_ACCESS_TOKEN;
             //const response = await axios.get(`https://cdn.contentful.com/spaces/muawvkdwcb84/environments/master/entries/${id}?access_token=tv6wB_pdLWmMO_XJ9EDcaDkqC5cewpa21jqsT5MLsUA`)
             const response = await axios.get(`https://cdn.contentful.com/spaces/${spaceId}/environments/master/entries/${id}?access_token=${accessToken}`)
             //setDealsData(response.data.items);
             //console.log("single Product data:" + JSON.stringify(response));
             
             setSingleDealData(response.data.fields);
             setAssetId(response.data.fields["image"]?.["sys"].id.toString());
        }

        if(searchUrlParams.get("id") != null) {
            fetchSingleDeal(searchUrlParams.get("id"));
        }
 
    }, []);

    //get a single record 
    //https://cdn.contentful.com/spaces/muawvkdwcb84/environments/master/entries/{entry_id}?access_token=tv6wB_pdLWmMO_XJ9EDcaDkqC5cewpa21jqsT5MLsUA

    return (
        <React.Fragment>

        <div className="single-product-area" key="">
            <div className="zigzag-bottom"></div>
            <div className="container">
                <div className="row">

                    <div className="col-md-12">
                        <div className="product-content-right">
                            <div className="product-breadcroumb">
                                <a href="/">Home</a>
                                <a href={'/product' + keyword}>{keyword.replaceAll('+',' ')}</a>
                            </div>
                            
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="product-images">
                                        <div className="product-main-img">
                                            {
                                                (assetId != undefined) ? <ImageContent assetId={assetId}></ImageContent> : ''
                                            }
                                        </div>
                                        
                                        <div className="product-gallery">
                                            
                                                <img src="" alt="" />
                                         
                                                                                        
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="col-sm-6">
                                    <div className="product-inner">
                                        <h2 className="product-name">{singleDealData.name}</h2>
                                        <div className="product-inner-price">
                                            {/* {item.price.currency} */}
                                            <ins>${singleDealData.price}</ins> 
                                            <del>${singleDealData.retailPrice}</del> 
                                            <span className='text-muted'>
                                                + Free Shipping
                                            </span>
                                            <span className='text-muted'>&nbsp;at eBay</span>
                                        </div>    
                                        
                                        {/* <div className="quantity">
                                            <input type="number" size="4" className="input-text qty text" title="Qty" value="1" name="quantity" min="1" step="1" />
                                        </div> */}
                                        {/* <button className="add_to_cart_button" type="submit">SEE IT</button> */}
                                        <a target="_blank" className="add_to_cart_button" href="">SEE IT</a>
                                        
                                        {/* <div className="product-shipping">
                                            <p>
                                                Shipping: 
                                                { 
                                                    (item.shippingOptions[0].shippingCost.value === "0.00") 
                                                    ?  "Free" 
                                                    : item.shippingOptions[0].shippingCost.currency + " " + item.shippingOptions[0].shippingCost.value
                                                }
                                            </p>
                                        </div> */}

                                        <div className="product-inner-category">
                                            {/* <p>Category: <a href="">Summer</a>. Tags: <a href="">awesome</a>, <a href="">best</a>, <a href="">sale</a>, <a href="">shoes</a>. </p> */}
                                        </div> 
                                        
                                        {/* <div role="tabpanel">
                                            <ul className="product-tab" role="tablist">
                                                <li role="presentation" className="active"><a href="#home" aria-controls="home" role="tab" data-toggle="tab">Description</a></li>
                                                <li role="presentation"><a href="#profile" aria-controls="profile" role="tab" data-toggle="tab">Reviews</a></li>
                                            </ul>
                                            <div className="tab-content">
                                                <div role="tabpanel" className="tab-pane fade in active" id="home">
                                                    <h2>Product Description</h2>  
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam tristique, diam in consequat iaculis, est purus iaculis mauris, imperdiet facilisis ante ligula at nulla. Quisque volutpat nulla risus, id maximus ex aliquet ut. Suspendisse potenti. Nulla varius lectus id turpis dignissim porta. Quisque magna arcu, blandit quis felis vehicula, feugiat gravida diam. Nullam nec turpis ligula. Aliquam quis blandit elit, ac sodales nisl. Aliquam eget dolor eget elit malesuada aliquet. In varius lorem lorem, semper bibendum lectus lobortis ac.</p>

                                                    <p>Mauris placerat vitae lorem gravida viverra. Mauris in fringilla ex. Nulla facilisi. Etiam scelerisque tincidunt quam facilisis lobortis. In malesuada pulvinar neque a consectetur. Nunc aliquam gravida purus, non malesuada sem accumsan in. Morbi vel sodales libero.</p>
                                                </div>
                                                <div role="tabpanel" className="tab-pane fade" id="profile">
                                                    <h2>Reviews</h2>
                                                    <div className="submit-review">
                                                        <p><label for="name">Name</label> <input name="name" type="text" /></p>
                                                        <p><label for="email">Email</label> <input name="email" type="email" /></p>
                                                        <div className="rating-chooser">
                                                            <p>Your rating</p>

                                                            <div className="rating-wrap-post">
                                                                <i className="fa fa-star"></i>
                                                                <i className="fa fa-star"></i>
                                                                <i className="fa fa-star"></i>
                                                                <i className="fa fa-star"></i>
                                                                <i className="fa fa-star"></i>
                                                            </div>
                                                        </div>
                                                        <p><label for="review">Your review</label> <textarea name="review" id="" cols="30" rows="10"></textarea></p>
                                                        <p><input type="submit" value="Submit" /></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                        
                                    </div>
                                </div>
                            </div>
                            

                            <TwitterShareButton
									url={window.location.href}
									title={singleDealData.name}
								>
                                <TwitterIcon size={32} round={true}></TwitterIcon>
                                </TwitterShareButton>
                             
                                
                            
                            <div className="related-products-wrapper">
                                <h2 className="related-products-title">Related Products</h2>
                                {/* <div className="related-products-carousel">
                                    <div className="single-product">
                                        <div className="product-f-image">
                                            <img src="../../assets/img/product-1.jpg" alt=""/>
                                            <div className="product-hover">
                                                <a href="" className="add-to-cart-link"><i className="fa fa-shopping-cart"></i> Add to cart</a>
                                                <a href="" className="view-details-link"><i className="fa fa-link"></i> See details</a>
                                            </div>
                                        </div>

                                        <h2><a href="">Sony Smart TV - 2015</a></h2>

                                        <div className="product-carousel-price">
                                            <ins>$700.00</ins> <del>$100.00</del>
                                        </div> 
                                    </div>
                                    <div className="single-product">
                                        <div className="product-f-image">
                                            <img src="../../assets/img/product-2.jpg" alt=""/>
                                            <div className="product-hover">
                                                <a href="" className="add-to-cart-link"><i className="fa fa-shopping-cart"></i> Add to cart</a>
                                                <a href="" className="view-details-link"><i className="fa fa-link"></i> See details</a>
                                            </div>
                                        </div>

                                        <h2><a href="">Apple new mac book 2015 March :P</a></h2>
                                        <div className="product-carousel-price">
                                            <ins>$899.00</ins> <del>$999.00</del>
                                        </div> 
                                    </div>
                                    <div className="single-product">
                                        <div className="product-f-image">
                                            <img src="../../assets/img/product-3.jpg" alt=""/>
                                            <div className="product-hover">
                                                <a href="" className="add-to-cart-link"><i className="fa fa-shopping-cart"></i> Add to cart</a>
                                                <a href="" className="view-details-link"><i className="fa fa-link"></i> See details</a>
                                            </div>
                                        </div>

                                        <h2><a href="">Apple new i phone 6</a></h2>

                                        <div className="product-carousel-price">
                                            <ins>$400.00</ins> <del>$425.00</del>
                                        </div>                                 
                                    </div>
                                    <div className="single-product">
                                        <div className="product-f-image">
                                            <img src="../../assets/img/product-4.jpg" alt=""/>
                                            <div className="product-hover">
                                                <a href="" className="add-to-cart-link"><i className="fa fa-shopping-cart"></i> Add to cart</a>
                                                <a href="" className="view-details-link"><i className="fa fa-link"></i> See details</a>
                                            </div>
                                        </div>

                                        <h2><a href="">Sony playstation microsoft</a></h2>

                                        <div className="product-carousel-price">
                                            <ins>$200.00</ins> <del>$225.00</del>
                                        </div>                            
                                    </div>
                                    <div className="single-product">
                                        <div className="product-f-image">
                                            <img src="../../assets/img/product-5.jpg" alt=""/>
                                            <div className="product-hover">
                                                <a href="" className="add-to-cart-link"><i className="fa fa-shopping-cart"></i> Add to cart</a>
                                                <a href="" className="view-details-link"><i className="fa fa-link"></i> See details</a>
                                            </div>
                                        </div>

                                        <h2><a href="">Sony Smart Air Condtion</a></h2>

                                        <div className="product-carousel-price">
                                            <ins>$1200.00</ins> <del>$1355.00</del>
                                        </div>                                 
                                    </div>
                                    <div className="single-product">
                                        <div className="product-f-image">
                                            <img src="../../assets/img/product-6.jpg" alt=""/>
                                            <div className="product-hover">
                                                <a href="" className="add-to-cart-link"><i className="fa fa-shopping-cart"></i> Add to cart</a>
                                                <a href="" className="view-details-link"><i className="fa fa-link"></i> See details</a>
                                            </div>
                                        </div>

                                        <h2><a href="">Samsung gallaxy note 4</a></h2>

                                        <div className="product-carousel-price">
                                            <ins>$400.00</ins>
                                        </div>                            
                                    </div>                                    
                                </div> */}
                            </div>
                        </div>                    
                    </div>
                </div>
            </div>
        </div>

        </React.Fragment>             
    )
}


export default SingleDeal
